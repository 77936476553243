/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import Modal from "../components/Modal";

import ConfirmModal from "./ConfirmModal";

import { useLoadingStore, useModalStore, useSpotsStore } from "../stores/hooks";

import { DELIVERY_COMPLETE_TYPES } from "../utils/delivery";

const SelectItem = ({ delivery, checked, onSelect }) => {
  const handleSelect = () => {
    onSelect(delivery);
  };

  return (
    <div
      className="pt-10 flex justify-between items-center cursor-pointer"
      onClick={handleSelect}
      tabIndex={0}
    >
      <div>{delivery.bookId}</div>
      <div>
        <div
          className={`icon-checkbox-orange ${checked ? "checked" : ""}`}
        ></div>
      </div>
    </div>
  );
};

function SelectSameReceiverDeliveriesModal({
  delivery,
  sameReceiverDeliveries,
  onAfterSubmit,
  type,
}) {
  const { ...loadingActions } = useLoadingStore();
  const { openModal, closeModal } = useModalStore();
  const { ...spotsActions } = useSpotsStore();

  const [selectedDeliveries, setSelectedDeliveries] = useState([]);

  const handleSelect = (_delivery) => {
    const _prevIndex = selectedDeliveries.indexOf(_delivery);

    if (_prevIndex === -1) {
      setSelectedDeliveries((prev) => [...prev, _delivery]);
    } else {
      setSelectedDeliveries((prev) => prev.filter((d) => d !== _delivery));
    }
  };

  const handleSubmit = () => {
    closeModal();
    onAfterSubmit({
      type,
    });
  };

  const handleSubmitMultiple = (_type) => {
    closeModal();
    onAfterSubmit({
      type: _type,
      bookIds: selectedDeliveries.map((d) => d.bookId),
    });
  };

  const handleCheckSpots = async () => {
    if (selectedDeliveries.length === 0) {
      alert("배송건을 선택해주세요.");
      return;
    }

    try {
      loadingActions.startLoading();
      const response = await Promise.allSettled(
        selectedDeliveries.map((d) => spotsActions.fetchById(d.spotId)),
      );

      const failed = response.filter((el) => el.status !== "fulfilled");

      if (failed.length > 0) {
        loadingActions.finishLoading();

        openModal(
          <ConfirmModal
            key="confirm-modal"
            message="동일수하인 수거지 조회에 실패했습니다. 단건 처리로 진행해 주세요."
            onSubmit={handleSubmit}
          />,
        );
      } else {
        loadingActions.finishLoading();

        const mustSignature = delivery.mustSignature || delivery.spotKurlyDrink;
        const deliveriesKurlyDrink = response.filter(
          (el) => el.value?.kurlyDrink,
        );
        const deliveriesMustSignature = selectedDeliveries.filter(
          (el) => el.mustSignature,
        );

        if (
          type === DELIVERY_COMPLETE_TYPES.SIGNATURE ||
          (mustSignature &&
            deliveriesKurlyDrink.length === selectedDeliveries.length) ||
          (!mustSignature &&
            deliveriesKurlyDrink.length === 0 &&
            deliveriesMustSignature.length === 0)
        ) {
          handleSubmitMultiple(type);
        } else {
          openModal(
            <ConfirmModal
              key="confirm-modal"
              message="주류사 주문건이 포함되어 있어 사진 촬영으로 배송 완료가 불가합니다. 서명 완료로 진행하시겠습니까?"
              onSubmit={() =>
                handleSubmitMultiple(DELIVERY_COMPLETE_TYPES.SIGNATURE)
              }
            />,
          );
        }
      }
    } catch (error) {
      window.alert(error.message);
      loadingActions.finishLoading();
    }
  };

  return (
    <Modal>
      <div className="p-10">
        <h2 className="font-medium text-18 mb-20">동일수하인 동시 완료 선택</h2>

        <div className="grid gap-10 divide-y divide-gray-d mb-10">
          {sameReceiverDeliveries.map((d) => (
            <SelectItem
              key={d.bookId}
              delivery={d}
              checked={selectedDeliveries.includes(d)}
              onSelect={handleSelect}
            />
          ))}
        </div>

        <div className="flex justify-center items-center">
          <button type="button" className="flex-1 p-10" onClick={handleSubmit}>
            단건완료
          </button>
          <button
            type="button"
            className="text-orange flex-1 p-10"
            onClick={handleCheckSpots}
          >
            동시완료
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default SelectSameReceiverDeliveriesModal;
