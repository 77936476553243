/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import api, { riderId } from "../Api";

const spotsAtom = atom({
  key: "spots",
  default: [],
});

export const useSpotsStore = () => {
  const [spots, setSpots] = useRecoilState(spotsAtom);

  /**
   * 수거지 상세 조회
   */
  const fetchById = useCallback(
    async (id) => {
      const spot = spots.find((s) => s.id === id);

      if (spot) {
        return spot;
      }

      const _spot = await api.get(`/spots/${id}`);

      if (_spot) {
        setSpots((prev) => [...prev, _spot]);
      } else {
        throw new Error("수거지를 찾을 수 없습니다.");
      }
    },
    [api.get, spots],
  );

  const state = {
    spots,
  };

  return { state, fetchById };
};
