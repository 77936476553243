/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import Modal from "../components/Modal";

import { useModalStore } from "../stores/hooks";

function ConfirmModal({
  message,
  cancelText = "아니오",
  submitText = "예",
  onSubmit,
  onCancel,
}) {
  const { closeModal } = useModalStore();

  const handleCancel = () => {
    closeModal();
    if (onCancel) {
      onCancel();
    }
  };

  const handleSubmit = () => {
    closeModal();
    onSubmit();
  };

  return (
    <Modal>
      <div className="">
        <div className="pt-20 px-15 mb-20">
          <h5 className="text-16 text-center">{message}</h5>
        </div>
        <div className="flex justify-center items-center border-t-1 border-gray-8">
          <button type="button" className="flex-1 p-10" onClick={handleCancel}>
            {cancelText}
          </button>
          <button
            type="button"
            className="flex-1 p-10 border-l-1 border-gray-8"
            onClick={handleSubmit}
          >
            {submitText}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmModal;
