/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Modal from "../components/Modal";

import AlertModal from "./AlertModal";

import {
  useDeliveriesStore,
  useLoadingStore,
  useModalStore,
} from "../stores/hooks";

import { CANCEL_REASONS, REASON_ETC } from "../utils/cancelPickup";
import {
  goToDeliveries,
  sendSmsMessageForDirect,
  sendToastMessage,
} from "../utils/postMessages";

const ReasonItem = ({ active, reason, onSelect }) => {
  const handleSelect = () => {
    onSelect(reason);
  };

  return (
    <div>
      <label className="block mb-10" onClick={handleSelect}>
        <input
          type="radio"
          name="corp"
          defaultChecked={!!active}
          className="mr-5"
        />
        {reason.text}
      </label>
    </div>
  );
};

function CancelDeliveryPickupReturnModal({ delivery }) {
  const { ...actions } = useDeliveriesStore();

  const { ...loadingActions } = useLoadingStore();

  const { openModal, closeModal } = useModalStore();

  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    reset({
      code: CANCEL_REASONS[0].code,
    });

    setLoad(true);
  }, []);

  // 분실완료 취소
  const handleCancel = () => {
    closeModal();
  };

  // 사유 선택
  const handleSelectReason = (reason) => {
    setValue("code", reason.code);
    setValue("text", reason.text);
    setValue("etcText", "");
  };

  // 직접입력 전체 삭제
  const handleDeleteEtcText = () => {
    setValue("etcText", "");
  };

  const onSubmit = async ({ code, text, etcText }) => {
    try {
      loadingActions.startLoading();

      const reasonText = `${text}${
        code === REASON_ETC && etcText ? `(${etcText})` : ""
      }`;

      const data = {
        allocationMatchType: "PICKUP",
        reason: reasonText,
        reasonType: code,
      };

      await actions.cancel({
        bookId: delivery.bookId,
        data,
      });

      closeModal();

      sendMessage({ reasonText });

      openModal(
        <AlertModal
          key="alert-modal"
          message={`${reasonText}, 취소 되었습니다.`}
          onSubmit={goToDeliveries}
        />,
      );
    } catch (e) {
      window.alert(`수거 취소에 실패했습니다. ${e.message}`);
    }

    loadingActions.finishLoading();
  };

  /**
   * 취소 안내 문자 발송
   */
  const sendMessage = ({ reasonText }) => {
    if (delivery.senderMobile) {
      const message = `[두발히어로 반품 수거취소 안내] 고객님 ${delivery.receiverName} 물품의 반품 수거가 아래 사유로 취소되었습니다.\n■반품/수거 접수처 : ${delivery.receiverName}\n■접수번호 : ${delivery.bookId}\n■방문주소 : ${delivery.senderAddressRoad}\n■취소사유 : ${reasonText}\n반품 수거 진행이 가능하다면 문자나 전화로 연락주시거나, 구매처로 연락 부탁드립니다.`;

      sendSmsMessageForDirect({
        mobile: delivery.senderMobile,
        message,
      });

      sendToastMessage("취소 안내 메시지를 발송했습니다.");
    }
  };

  return (
    <Modal>
      <div className="p-15">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-20">
            <h5 className="font-medium text-18 mb-10">
              {delivery.bookId}{" "}
              {delivery.senderAddress || delivery.senderAddressRoad || ""}{" "}
              {delivery.senderAddressDetail}
              <br />
              수거 취소 사유를 선택해주세요.
            </h5>
            <div>
              {load &&
                CANCEL_REASONS.map((reason) => {
                  return (
                    <ReasonItem
                      key={reason.code}
                      active={reason.code === watch("code")}
                      reason={reason}
                      onSelect={handleSelectReason}
                    />
                  );
                })}
              <input name="code" {...register("code")} hidden />
              <input name="text" {...register("text")} hidden />
              <div className="bg-[#f6f6f6] p-5 flex justify-between items-center ml-10">
                <input
                  type="text"
                  className="bg-transparent max-w-[calc(100vw-120px)] flex-1"
                  {...register("etcText")}
                  disabled={watch("code") !== REASON_ETC}
                />
                <button
                  type="button"
                  className="text-[#999] p-5 w-50"
                  onClick={handleDeleteEtcText}
                >
                  삭제
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <button
              type="button"
              className="flex-1 p-10"
              onClick={handleCancel}
            >
              취소
            </button>
            <button type="submit" className="text-orange flex-1 p-10">
              확인
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default CancelDeliveryPickupReturnModal;
